
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux' 

import { addToCart, loadRemoveFromBasket } from '../../actions/basket-actions'

class removeFromBasket extends Component {

  constructor(props) {
    super(props);  
  }

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = { 
    basket: PropTypes.object.isRequired,
    addToCart: PropTypes.func.isRequired,
    loadRemoveFromBasket: PropTypes.func.isRequired,
  }

  removeFromCart(testId) {  
    
   
 
    var page = this;
    var basket = this.props.basket;

    var partner = this.props.partner;
    var partnerId = 0;
    if (Object.keys(partner).length > 0) {
      partnerId = partner[0].partnerId;
    } 
    
    for (var key in basket) {
        if (basket[key] == testId) {

          var phlebotomyType = 'Finger-prick';
          if (this.props.phlebotomyOption == 2 || this.props.phlebotomyOption == 3) {
            phlebotomyType = 'Phlebotomy';
          } else if (page.props.phlebotomyOption == 4) { 
            phlebotomyType = 'Swab';
          } else {
            phlebotomyType = 'Finger-prick';
          }
          //delete basket[key];
          window.dataLayer.push({
            'event':'EERemoveFromBasket',
            'ecommerce': {
              'remove': {
                'products': [{
                  'name': this.props.testName,
                  'id': 'F'+testId,
                  'price': this.props.testPrice,
                  'category': 'Uncategorised',
                  'variant': phlebotomyType,
                }]
              }
            }
          });

          delete basket[key];
        } 
    }

    var count = 0;

    for (var key in basket) {
      count++;
    }  
    var dt, expires;
    var cname="hasBasketItems";
    var cvalue = count;
    var days = 999;
    dt = new Date();
    dt.setTime(dt.getTime()+(days*24*60*60*1000));
    expires = "; expires="+dt.toGMTString();
    document.cookie = cname+"="+cvalue+expires+'; domain=forthwithlife.co.uk';
 
    localStorage.setItem("basket", JSON.stringify(basket));
 
    this.props.addToCart(basket)

    if (localStorage.getItem("orderCookieId") != "") {
      this.props.loadRemoveFromBasket(testId, localStorage.getItem("orderCookieId"), partnerId).then(function(response) {
        page.props.reload();
      });
     
    }
 
  }

  render() {

    let randomModalString =  Math.floor(Math.random() * 1001);

    return (
      <>
      
      {this.props.small ? (
        <>
        <span data-bs-toggle="modal" data-bs-target={"#deleteMarker"+randomModalString} className="deleteMarker minus"><i className="fal fa-trash"></i></span>
        <div className="modal deleteMarkerModal" tabindex="-1" role="dialog" id={"deleteMarker"+randomModalString}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content shadow-sm border-0">
              <div className="border-0 modal-header text-center">
                <h5 className="mt-4 fw-bold modal-title w-100 biomarkerModalTestName no-letter-spacing">Remove from basket?</h5>
              </div> 
              <div className="modal-body">
                <button className="mb-3 removeFromCart btn" onClick={() => this.removeFromCart(this.props.testId)}>Remove</button>
                <button type="button" className="mb-3 cancelRemove btn" data-bs-dismiss="modal" aria-label="Close">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        </>
        
      ) : (
        <button className="removeFromCart btn" onClick={() => this.removeFromCart(this.props.testId)}>Delete</button>
      )} 
      </>
      
     
    
    )
  }
}


const mapStateToProps = (state, ownProps) => {

  const {
    basket,
    authentication, 
    user
  } = state
 

  return {
    authentication, 
    user,
    basket
  }
}

export default withRouter(connect(mapStateToProps, { 
  addToCart,
  loadRemoveFromBasket
})(removeFromBasket))       
