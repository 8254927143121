import { CALL_API, Schemas } from '../middleware/api'

export const ADD_TO_BASKET = 'ADD_TO_BASKET'
export const REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET'
export const REMOVE_ALL_FROM_BASKET = 'REMOVE_ALL_FROM_BASKET'
export const BASKET_FAILURE = 'BASKET_FAILURE'

export const addToCart= (id)=>{
    return{
        type: ADD_TO_BASKET,
        id
    }
}

export const removeFromCart= (id)=>{
    return{
        type: REMOVE_FROM_BASKET,
        id
    }
}

export const removeAllFromCart= ()=>{
    return{
        type: REMOVE_ALL_FROM_BASKET
    }
}
 


export const VOUCHER_REQUEST = 'VOUCHER_REQUEST'
export const VOUCHER_SUCCESS = 'VOUCHER_SUCCESS'
export const VOUCHER_FAILURE = 'VOUCHER_FAILURE'

const fetchCheckVoucherCode = (voucherCode, testIds, orderCookieId, subscriptionId = 0, globalPlusTests=[]) => ({
  voucherCode,
  testIds,
  orderCookieId,
  subscriptionId,
  globalPlusTests,
  [CALL_API]: {
    types: [ VOUCHER_REQUEST, VOUCHER_SUCCESS, VOUCHER_FAILURE ],
    endpoint: 'check-voucher',
    schema: null,
    method: 'POST', 
    body: {
      voucherCode: voucherCode,
      testIds: testIds,
      orderCookieId: orderCookieId,
      subscriptionId: subscriptionId,
      globalPlusTests: globalPlusTests,
    }
  }
})

export const checkVoucherCode = (voucherCode, testIds, orderCookieId, subscriptionId = 0, globalPlusTests=[]) => (dispatch, getState) => {
  return dispatch(fetchCheckVoucherCode(voucherCode, testIds, orderCookieId, subscriptionId, globalPlusTests))
};



export const BASKET_REQUEST = 'BASKET_REQUEST';
export const BASKET_SUCCESS = 'BASKET_SUCCESS'; 

const fetchAddToBasket = (testId, orderCookieId, customerId, purchaseGroup, bundleGroup, forthEdge, voucherCode, phlebotomy, update, deliveryOption, partnerId) => ({
  testId,
  orderCookieId,
  customerId,
  purchaseGroup,
  bundleGroup,
  forthEdge,
  voucherCode,
  phlebotomy,
  update,
  deliveryOption,
  partnerId,
  [CALL_API]: {
    types: [BASKET_REQUEST, BASKET_SUCCESS, BASKET_FAILURE],
    endpoint: 'basket/add-to-cart',
    method: 'POST',
    body: {
      testId,
      orderCookieId,
      customerId,
      purchaseGroup,
      bundleGroup,
      forthEdge,
      voucherCode,
      phlebotomy,
      update,
      deliveryOption,
      partnerId
    },
  },
});

export const loadAddToBasket = (testId, orderCookieId, customerId, purchaseGroup, bundleGroup, forthEdge, voucherCode, phlebotomy, update, deliveryOption, partnerId) => (dispatch) => {
  return dispatch(fetchAddToBasket(testId, orderCookieId, customerId, purchaseGroup, bundleGroup, forthEdge, voucherCode, phlebotomy, update, deliveryOption, partnerId));
};


const fetchClearBasket = (orderCookieId) => ({
  orderCookieId,
  [CALL_API]: {
    types: [BASKET_REQUEST, BASKET_SUCCESS, BASKET_FAILURE],
    endpoint: 'basket/clear-basket',
    method: 'POST',
    body: {
      orderCookieId
    },
  },
});

export const loadClearBasket = (orderCookieId) => (dispatch) => {
  return dispatch(fetchClearBasket(orderCookieId));
};


const fetchRemoveFromBasket = (testId, orderCookieId, partnerId) => ({
  testId,
  orderCookieId,
  partnerId,
  [CALL_API]: {
    types: [BASKET_REQUEST, BASKET_SUCCESS, BASKET_FAILURE],
    endpoint: 'basket/remove-from-cart',
    method: 'POST',
    body: {
      testId,
      orderCookieId,
      partnerId,
    },
  },
});

export const loadRemoveFromBasket = (testId, orderCookieId, partnerId) => (dispatch) => {
  return dispatch(fetchRemoveFromBasket(testId, orderCookieId, partnerId));
};



export const BASKET_UPDATE_REFERENCE_REQUEST = 'BASKET_UPDATE_REFERENCE_REQUEST';
export const BASKET_UPDATE_REFERENCE_SUCCESS = 'BASKET_UPDATE_REFERENCE_SUCCESS'; 
export const BASKET_UPDATE_REFERENCE_FAILURE = 'BASKET_UPDATE_REFERENCE_FAILURE'; 

const fetchUpdateBasketReference = (userId, subscriptionOrder, orderCookieId) => ({
  userId,
  subscriptionOrder,
  orderCookieId,
  [CALL_API]: {
    types: [BASKET_UPDATE_REFERENCE_REQUEST, BASKET_UPDATE_REFERENCE_SUCCESS, BASKET_UPDATE_REFERENCE_FAILURE],
    endpoint: '/basket/update-order-reference',
    method: 'POST',
    body: {
      userId,
      subscriptionOrder,
      orderCookieId,
    },
  },
});

export const loadUpdateBasketReference = (userId, subscriptionOrder, orderCookieId) => (dispatch) => {
  return dispatch(fetchUpdateBasketReference(userId, subscriptionOrder, orderCookieId));
};



export const CART_REQUEST = 'CART_REQUEST'
export const CART_SUCCESS = 'CART_SUCCESS'
export const CART_FAILURE = 'CART_FAILURE'

const fetchGetCart = (orderCookieId, overrideOrderId, partnerId) => ({
  orderCookieId, 
  overrideOrderId,
  partnerId,
  [CALL_API]: {
    types: [ CART_REQUEST, CART_SUCCESS, CART_FAILURE ],
    endpoint: `/basket/get-cart/${orderCookieId}/${overrideOrderId}/${partnerId}`,
    schema: Schemas.CART_ARRAY
  }
})

export const loadGetCart = (orderCookieId, overrideOrderId, partnerId) => (dispatch, getState) => {
  const cart = getState().entities.cart;
 
  if (Object.keys(cart).length > 0) {
    Object.keys(cart).map(function(key) {

      delete cart[key];

    })  
  }
  return dispatch(fetchGetCart(orderCookieId, overrideOrderId, partnerId))
}


export const UPDATE_PAYMENT_INTENT_REQUEST = 'UPDATE_PAYMENT_INTENT_REQUEST'
export const UPDATE_PAYMENT_INTENT_SUCCESS = 'UPDATE_PAYMENT_INTENT_SUCCESS'
export const UPDATE_PAYMENT_INTENT_FAILURE = 'UPDATE_PAYMENT_INTENT_FAILURE'

const fetchPaymentIntent= (body) => ({
  [CALL_API]: {
    types: [ UPDATE_PAYMENT_INTENT_REQUEST, UPDATE_PAYMENT_INTENT_SUCCESS, UPDATE_PAYMENT_INTENT_FAILURE ],
    endpoint: '/checkout/payment-intent',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updatePaymentIntent = (body) => (dispatch, getState) => {
  return dispatch(fetchPaymentIntent(body))
};





export const PARTNER_CLINIC_REQUEST = 'PARTNER_CLINIC_REQUEST'
export const PARTNER_CLINIC_SUCCESS = 'PARTNER_CLINIC_SUCCESS'
export const PARTNER_CLINIC_FAILURE = 'PARTNER_CLINIC_FAILURE'

const fetchPartnerClinics = () => ({
  [CALL_API]: {
    types: [ PARTNER_CLINIC_REQUEST, PARTNER_CLINIC_SUCCESS, PARTNER_CLINIC_FAILURE ],
    endpoint: `/basket/get-partner-clinics`,
    schema: Schemas.PARTNER_CLINIC_ARRAY
  }
})

export const loadPartnerClinics = () => (dispatch, getState) => {
  return dispatch(fetchPartnerClinics())
}

export const ADD_CREDIT_REQUEST = 'ADD_CREDIT_REQUEST'
export const ADD_CREDIT_SUCCESS = 'ADD_CREDIT_SUCCESS'
export const ADD_CREDIT_FAILURE = 'ADD_CREDIT_FAILURE'

const fetchAddCredit = (customerId, credit) => ({
  [CALL_API]: {
    types: [ ADD_CREDIT_REQUEST, ADD_CREDIT_SUCCESS, ADD_CREDIT_FAILURE ],
    endpoint: `/checkout/add-credit/${credit}/${customerId}`,
    schema: Schemas.CART_ARRAY
  }
})

export const addCredit = (customerId, credit) => (dispatch, getState) => {
  return dispatch(fetchAddCredit(customerId, credit))
}