import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, Provider } from 'react-redux'
import { Route, Switch, withRouter, matchPath } from 'react-router-dom'
 
import ReactGA from 'react-ga'; 
import ReactPixel from 'react-facebook-pixel';
import IdleTimer from 'react-idle-timer';
 


import { loadUser } from '../actions/users-actions'
import { loadValidate, logout } from '../actions/authentication-actions'

import asyncComponent from "../components/AsyncComponent";
import PrivateRoute from '../components/PrivateRoute'

import Footer from '../components/Footer'
import Header from './Header'
import Sidebar from './Sidebar'

const Home = asyncComponent(() => import("../containers/Home"));
const Category = asyncComponent(() => import("../containers/Category"));
const SubNavigation = asyncComponent(() => import("../containers/SubNavigation"));
const TestOverview = asyncComponent(() => import("../containers/TestOverview"));
const Results = asyncComponent(() => import("../containers/Results"));
const MarkerResult = asyncComponent(() => import("../containers/MarkerResult"));
const Login = asyncComponent(() => import("../containers/Login"));
const ResetPassword = asyncComponent(() => import("../containers/ResetPassword"));
const Register = asyncComponent(() => import("../containers/Register"));
const MyAccount = asyncComponent(() => import("../containers/MyAccount"));
const MyOrders = asyncComponent(() => import("../containers/MyOrders"));
const MySubscriptions = asyncComponent(() => import("../containers/MySubscriptions"));
const MySettings = asyncComponent(() => import("../containers/MySettings"));
const Page404 = asyncComponent(() => import("../containers/Page404"));
const ExclusiveOffers = asyncComponent(() => import("../containers/ExclusiveOffers"));
const PartnerAccess = asyncComponent(() => import("../containers/PartnerAccess"));
const Chat = asyncComponent(() => import("../containers/Chat"));
const BespokeTest = asyncComponent(() => import("../containers/BespokeTest"));
const Shop = asyncComponent(() => import("../containers/Shop"));
const Product = asyncComponent(() => import("../containers/Product"));
const Checkout = asyncComponent(() => import("../containers/Checkout"));
const CheckoutFromWebsite = asyncComponent(() => import("../containers/CheckoutFromWebsite"));
const Activate = asyncComponent(() => import("../containers/Activate"));
const UpdatePassword = asyncComponent(() => import("../containers/UpdatePassword"));
const AccountCredit = asyncComponent(() => import("../containers/AccountCredit"));
const HormoneTestResults = asyncComponent(() => import("../containers/HormoneTestResults"));
const HormoneReport = asyncComponent(() => import("../containers/HormoneReport"));
const GetStarted = asyncComponent(() => import("../containers/GetStarted"));
const PilotContactDetails = asyncComponent(() => import("../containers/PilotContactDetails"));
const EditSubscription = asyncComponent(() => import("../containers/EditSubscription"));
const SendYourSample = asyncComponent(() => import("../containers/SendYourSample"));
const ReturnFromTrustpilot = asyncComponent(() => import("../containers/ReturnFromTrustpilot"));

class Root extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,  
    loadUser: PropTypes.func.isRequired,
    loadValidate: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  componentWillMount() { 
    const token = localStorage && localStorage.getItem('token') ? localStorage.getItem('token') : false;
    const authenticated = this.props.authentication.isAuthenticated;
    if (token && authenticated) { 
      this.props.loadValidate()
        .then((response) => { 
          this.props.loadUser('me')
        })
    } 
    this.props.history.listen(location => window.scrollTo(0, 0));
  }
 

  componentDidMount() {
    if (process.env.NODE_ENV != 'development') {
      ReactPixel.init('184476205260993');
      ReactPixel.pageView();
      ReactPixel.fbq('track', 'PageView');
    } 

    this.taskRunner();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (process.env.NODE_ENV != 'development') {
        ReactPixel.init('184476205260993');
        ReactPixel.pageView();
        ReactPixel.fbq('track', 'PageView');
      } 
    }
  }



taskRunner() {
  setInterval((function(page){
    return function(){
      
      var triggerLogout = localStorage.getItem('triggerLogout'); 
      if (triggerLogout == 'true') {

        localStorage.setItem("triggerLogout", false);
        page.props.logout(); 

      }
    };
  })(this),800);
}

   


  _onAction(e) { 
  }
 
  _onActive(e) { 
  }
 
  _onIdle(e) {
    var appWindow = window;
    appWindow.postMessage(JSON.stringify({type: 'inactive', response: ''}), '*');
  }

  timer() {
    return(
      <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={5 * 60 * 1000} />
    )
  }
 
  render() {
    const { store } = this.props
    const user = this.props.user;
    const userId = user.iD; 
    var isIosWebview =/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = window.isRNWebView;
    var exclusiveOffers = '';
    
    if ((userId != 94 || userId != 405 || userId != 41206) && (isAndroid || isIosWebview)) {
      exclusiveOffers = <PrivateRoute exact path="/exclusive-offers" component={ExclusiveOffers}/>
    }

    if (userId == 94 || userId == 405 || userId == 41206) {
      document.body.classList.add('demo');
    } else {
      document.body.classList.remove('demo');
    }
       
 
    
    if (this.props.location.pathname === "/sign-in" || this.props.location.pathname === "/demo" || this.props.location.pathname === "/fhmdemo") {
      return (
        <Provider store={store}>
          <main role="main" className="login-page">
            {this.timer()}
            <Switch>
              <Route exact path="/(sign-in|demo|fhmdemo)" component={Login} />
            </Switch>
          </main>
        </Provider>
      )
    } else if (this.props.location.pathname === "/reset-password/") {
      return (
        <Provider store={store}>
          <main role="main" className="login-page">
            {this.timer()}
            <Switch>
                <Route exact path="/reset-password/" component={ResetPassword} />
            </Switch>
          </main>
        </Provider>
      )
 
    } else if ((userId != 94 || userId != 405 || userId != 41206) && (this.props.location.pathname === "/activate/" || this.props.location.pathname === "/activate" || window.location.pathname.split('/')[1] == 'activate')) {
      return (
        <Provider store={store}>
          <main role="main" className="activate-page">
            <Switch>
                <Route exact path="/activate/:activationToken?" component={Activate} />
            </Switch>
          </main>
        </Provider>
       )
 
    } else if (matchPath(this.props.location.pathname, { path: '/forgotten-password/:token' })) {
      return (
        <Provider store={store}>
          <main role="main" className="login-page">
            <Switch>
                <Route exact path="/forgotten-password/:token" component={UpdatePassword} /> 
 
            </Switch>
          </main>
        </Provider>
      )
 
    } else if (userId == 94 || userId == 405 || userId == 41206) {
      return (
        <Provider store={store}>
            <div class="d-block toggled" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
            {window.location.pathname.split('/')[1] != 'bespoke-test' && window.location.pathname.split('/')[1] != 'checkout' && window.location.pathname.split('/')[1] != 'sign-in' && window.location.pathname.split('/')[1] != 'edit-test' && window.location.pathname != '/chat' ? <Header /> : null}
            <Route exact path="/(category|results)/:orderId"  component={SubNavigation} />
            <Route exact path="/category/:orderId/:categoryType/:slug"  component={SubNavigation} />
            {/* <Route exact path="/result/:orderId/:biomarkerId/:slug"  component={SubNavigation} /> */}
            <main role="main" className="container">
              {this.timer()}
              <Switch>
                <PrivateRoute exact path="/" component={Home}/>
                <PrivateRoute exact path="/category/:orderId/:categoryType/:slug" component={Category} />
                <PrivateRoute exact path="/results/:orderId" component={TestOverview} />
                <PrivateRoute exact path="/result/:orderId/:biomarkerId/:slug" component={MarkerResult} />
                <PrivateRoute exact path="/results" component={Results} /> 
                <PrivateRoute exact path="/hormone-results/:orderId" component={HormoneTestResults} />
                <PrivateRoute exact path="/hormone-report/:orderId" component={HormoneReport} />
                <Route exact path="/chat/:hideCloseButton?" component={Chat} /> 
                <Route exact path="/bespoke-test/:parentTestId/:testType?/:selectedTests?" component={BespokeTest} />
                <Route exact path="/edit-test/:editTestId" component={BespokeTest} /> 
                <Route exact path="/amend-subscription/parentTestId/:subscriptionId?" component={BespokeTest} /> 
                <PrivateRoute exact path="/results" component={Results} />  
                {/*
                <Route exact path="/shop" component={Shop} />
                <Route exact path="/shop/:slug" component={Product} />
                */}
                <Route exact path="/(sign-in|demo|fhmdemo)" component={Login} /> 
                <Route exact path="/sign-up" component={Register} />  
                <Route component={Page404} />
              </Switch>
            </main>
            {window.location.pathname.split('/')[1] != 'bespoke-test' && window.location.pathname.split('/')[1] != 'checkout' && window.location.pathname.split('/')[1] != 'sign-in' && window.location.pathname.split('/')[1] != 'edit-test' && window.location.pathname != '/chat' ? <Footer /> : null}   
            </div>
          </div>

        </Provider>
      )

    } else { 
      return (
        <Provider store={store}>
            <div class="d-block toggled" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
            {window.location.pathname.split('/')[1] != 'bespoke-test' && window.location.pathname.split('/')[1] != 'checkout' && window.location.pathname.split('/')[1] != 'sign-in' && window.location.pathname.split('/')[1] != 'edit-test' && window.location.pathname != '/chat' ? <Header /> : null}    
            <Route exact path="/(category|results)/:orderId"  component={SubNavigation} />
            <Route exact path="/category/:orderId/:categoryType/:slug"  component={SubNavigation} />
            {/* <Route exact path="/result/:orderId/:biomarkerId/:slug"  component={SubNavigation} /> */}
            <main role="main" className="container">
              {this.timer()}
              <Switch>
                <PrivateRoute exact path="/" component={Home}/>
                <PrivateRoute exact path="/get-started/:orderId?" component={GetStarted} />
                <PrivateRoute exact path="/contact-details/" component={PilotContactDetails} />
                <PrivateRoute exact path="/category/:orderId/:categoryType/:slug" component={Category} />
                <PrivateRoute exact path="/results/:orderId" component={TestOverview} />
                <PrivateRoute exact path="/hormone-results/:orderId" component={HormoneTestResults} />
                <PrivateRoute exact path="/hormone-report/:orderId" component={HormoneReport} />
                <PrivateRoute exact path="/result/:orderId/:biomarkerId/:slug" component={MarkerResult} />
                <PrivateRoute exact path="/results" component={Results} />
                <PrivateRoute exact path="/third-party-access/:accessCode?" component={PartnerAccess} />
                <PrivateRoute exact path="/edit-subscription/:subscriptionId/:testId?/:testType?" component={EditSubscription} />
                <PrivateRoute exact path="/amend-subscription/:parentTestId/:subscriptionId?/:phlebotomy?" component={BespokeTest} />
                <Route exact path="/edit-subscription-test/:editTestId?/:subscriptionId?/:phlebotomy?" component={BespokeTest} /> 
                <PrivateRoute exact path="/send-your-sample/:orderId?" component={SendYourSample} />
                
                {/*
                <Route exact path="/shop" component={Shop} />
                <Route exact path="/shop/:slug" component={Product} />
                */}
                <Route exact path="/checkout/" component={Checkout} />
                <Route exact path="/checkout/:basket/:voucherCode?" component={CheckoutFromWebsite} />
                <Route exact path="/add-to-basket/:basket/:voucherCode?" component={CheckoutFromWebsite} />
                <PrivateRoute exact path="/my-account" component={MyAccount} />
                <PrivateRoute exact path="/my-orders" component={MyOrders} />
                <PrivateRoute exact path="/my-subscriptions" component={MySubscriptions} />
                <PrivateRoute exact path="/my-settings" component={MySettings} />
                {exclusiveOffers}
                <Route exact path="/chat/:hideCloseButton?" component={Chat} /> 
                <Route exact path="/bespoke-test/:parentTestId/:testType?/:preData?/:chatTest?/:voucherCode?/:partnerId?" component={BespokeTest} /> 
                <Route exact path="/edit-test/:editTestId?/:phlebotomyType?" component={BespokeTest} /> 
                <Route exact path="/return-trustpilot/:orderId" component={ReturnFromTrustpilot} /> 
                <Route exact path="/(sign-in|demo|fhmdemo)" component={Login} />
                <Route exact path="/sign-up" component={Register} />  
                <Route exact path="/account-credit" component={AccountCredit} />  
                <Route component={Page404} />
              </Switch>
            </main>
            {window.location.pathname.split('/')[1] != 'bespoke-test' && window.location.pathname.split('/')[1] != 'checkout' && window.location.pathname.split('/')[1] != 'sign-in' && window.location.pathname.split('/')[1] != 'edit-test' && window.location.pathname != '/chat' ? <Footer /> : null}   
            </div>
          </div>

        </Provider>
      )

    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    authentication,
    user
  } = state

  return {
    authentication,
    user
  }
}

export default withRouter(connect(mapStateToProps, {
  loadUser,
  loadValidate,
  logout
})(Root))
