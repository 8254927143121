import "es6-string-polyfills";
import 'babel-polyfill'; 
import 'react-app-polyfill/ie11';
import 'es6-shim';
import 'isomorphic-fetch';
import 'html5shiv';
import 'core-js';

import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import Root from './containers/Root'
import configureStore from './store/configureStore'
import * as serviceWorker from './serviceWorker';
import config from "./config";

import 'bootstrap'
import './stylesheets/main.scss' 
 



const store = configureStore()
if (window.location.hostname == 'femalehormones.forthwithlife.co.uk' || window.location.hostname == 'app.forthwithlife.co.uk' || window.location.hostname == 'shop.forthwithlife.co.uk' || window.location.hostname == 'appdev.forthwithlife.co.uk' || window.location.hostname == 'localhost') {
  document.body.classList.add('life');

} else if (window.location.hostname == 'app.forthedge.co.uk' || window.location.hostname == 'appdev.forthedge.co.uk' || window.location.hostname == 'shop.forthedge.co.uk') {
  document.body.classList.add('edge');
  document.body.classList.add('dark');

} else if (window.location.hostname == 'app.biomarkertracking.com' || window.location.hostname == 'shop.biomarkertracking.com') {
  document.body.classList.add('edge');
  document.body.classList.add('dark');
  document.body.classList.add('biomarkertracking');
} else {
  document.body.classList.add('nonforth');
}


if (window.location.hostname == 'app.forthwithlife.co.uk' || window.location.hostname == 'shop.forthwithlife.co.uk') {     
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="manifest" href="/manifest.webmanifest">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#000000">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<meta name="msapplication-TileColor" content="#ffffff">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<meta name="theme-color" content="#ffffff">' );
  var z = document.createElement('script');
  z.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TSZR32F');";
  document.head.appendChild(z);
  var x = document.createElement('script');
  x.innerHTML = '!function(){window.sib={equeue:[],client_key:"y17dn737nzd8hnlxzkgdyb7c"},window.sendinblue={};for(var e=["track","identify","trackLink","page"],n=0;n<e.length;n++)!function(e){window.sendinblue[e]=function(){var n=Array.prototype.slice.call(arguments);(window.sib[e]||function(){var i={};i[e]=n,window.sib.equeue.push(i)})(n[0],n[1],n[2])}}(e[n]);var i=document.createElement("script");n=document.getElementsByTagName("script")[0];i.type="text/javascript",i.id="sendinblue-js",i.async=!0,i.src="https://sibautomation.com/sa.js?key="+window.sib.client_key,n.parentNode.insertBefore(i,n),window.sendinblue.page()}();';
  document.head.appendChild(x);
} else {
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="apple-touch-icon" sizes="180x180" href="/favicons/edgeapple-touch-icon.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="icon" type="image/png" sizes="32x32" href="/favicons/edgefavicon-32x32.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="icon" type="image/png" sizes="16x16" href="/favicons/edgefavicon-16x16.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="manifest" href="/edgemanifest.webmanifest">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="mask-icon" href="/favicons/edgesafari-pinned-tab.svg" color="#000000">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<meta name="msapplication-TileColor" content="#ffffff">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<meta name="theme-color" content="#ffffff">' );
  var z = document.createElement('script');
  z.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PLV9W5P');";
  document.head.appendChild(z);
}

if (window.location.hostname == 'shop.forthedge.co.uk') {     
  var x = document.createElement('script');
  x.innerHTML = '!function(){window.sib={equeue:[],client_key:"y17dn737nzd8hnlxzkgdyb7c"},window.sendinblue={};for(var e=["track","identify","trackLink","page"],n=0;n<e.length;n++)!function(e){window.sendinblue[e]=function(){var n=Array.prototype.slice.call(arguments);(window.sib[e]||function(){var i={};i[e]=n,window.sib.equeue.push(i)})(n[0],n[1],n[2])}}(e[n]);var i=document.createElement("script");n=document.getElementsByTagName("script")[0];i.type="text/javascript",i.id="sendinblue-js",i.async=!0,i.src="https://sibautomation.com/sa.js?key="+window.sib.client_key,n.parentNode.insertBefore(i,n),window.sendinblue.page()}();';
  document.head.appendChild(x);
}

render(
  <Router>
    <Root store={store} />
  </Router>,
  document.getElementById('root')
)

 
serviceWorker.unregister();
