import { normalize, schema } from 'normalizr'
import { camelizeKeys } from 'humps'

const queryStringFromObject = (queryObject) => {
  return Object.keys(queryObject).reduce(function(a,k){a.push(k + '=' + encodeURIComponent(queryObject[k]));return a},[]).join('&')
}

// Extracts the next page URL from Github API response.
const getNextPageUrl = response => {
  const link = response.headers.get('link')
  if (!link) {
    return null
  }

  const nextLink = link.split(',').find(s => s.indexOf('rel="next"') > -1)
  if (!nextLink) {
    return null
  }

  return nextLink.trim().split(';')[0].slice(1, -1)
}



const API_ROOT = process.env.NODE_ENV === 'development' ? 'http://192.168.33.10/' : 'https://apifemalehormones.forthwithlife.co.uk/'




// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.

 
function postRequest(url, data, schema) {

 

  var headers = {
    'Content-Type' : 'application/json'
  }
  
   
  if (localStorage.getItem('token')) { 
    headers = {
      'Content-Type' : 'application/json',
      'Authorization' : 'Bearer ' + localStorage.getItem('token')
    }
  }

 
  return fetch(url, {
    credentials: 'same-origin', // 'include', default: 'omit'
    method: 'POST', // 'GET', 'PUT', 'DELETE', etc.
    body:  data, // Coordinate the body type with 'Content-Type'
    headers: new Headers(
      headers
    ),
  })
  .then((response) => {
      return response.json()
        .then((json) => {
          if (!response.ok) {
            return Promise.reject(json)
          }
          const camelizedJson = camelizeKeys(json)
          const nextPageUrl = getNextPageUrl(response)

          return Object.assign({},
            schema ? normalize(camelizedJson, schema) : camelizedJson,
            // normalize(camelizedJson, schema),
            { nextPageUrl }
          )
        })
    })
}

function getRequest(url, schema) {

  var headers = {
    'Content-Type' : 'application/json'
  }
  
   
  if (localStorage.getItem('token')) { 
    headers = {
      'Content-Type' : 'application/json',
      'Authorization' : 'Bearer ' + localStorage.getItem('token')
    }
  }


  return fetch(url, {
    credentials: 'same-origin', // 'include', default: 'omit'
    method: 'GET', // 'GET', 'PUT', 'DELETE', etc. 
    headers: new Headers(
      headers
    ),
  })
  .then((response) => {
      return response.json()
        .then((json) => {
          if (!response.ok) {
            return Promise.reject(json)
          }
          const camelizedJson = camelizeKeys(json)
          const nextPageUrl = getNextPageUrl(response)
 
          return Object.assign({},
            schema ? normalize(camelizedJson, schema) : camelizedJson,
            // normalize(camelizedJson, schema),
            { nextPageUrl }
          )
        })
    })
}


const callApi = (endpoint, schema, method = 'GET', queryObject = '', body = {}) => {

  const queryString = queryStringFromObject(queryObject);

  const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint

   

  if (method === 'POST') {
    return postRequest(fullUrl + '?' + queryString, JSON.stringify(body), schema);
  } else {
    return getRequest(fullUrl + '?' + queryString, schema);
  }
}

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

// API may return results with uppercase letters while the query
// doesn't contain any. For example, "someuser" could result in "SomeUser"
// leading to a frozen UI as it wouldn't find "someuser" in the entities.
// That's why we're forcing lower cases down there.


const userSchema = new schema.Entity('users', {}, {
  idAttribute: user => user.iD
})

const medicalSchema = new schema.Entity('medical', {}, {
  idAttribute: medical => medical.iD
})

const orderSchema = new schema.Entity('order', {}, {
  idAttribute: order => order.orderId
})

const hormoneOrderSchema = new schema.Entity('hormoneOrder', {}, {
  idAttribute: hormoneOrder => hormoneOrder.orderId
})

const resultSchema = new schema.Entity('result', {}, {
  idAttribute: result => result.iD
})

const improvedResultsSchema = new schema.Entity('improvedResults', {}, {
  idAttribute: improvedResults => 0
})

const outOfRangeResultsSchema = new schema.Entity('outOfRangeResults', {}, {
  idAttribute: outOfRangeResults => 0
})

const lastOrderSchema = new schema.Entity('lastOrder', {}, {
  idAttribute: lastOrder => lastOrder.iD
})

const completeIncompleteMarkerSchema = new schema.Entity('completeIncompleteMarker', {}, {
  idAttribute: completeIncompleteMarker => 0
})

const testsSchema = new schema.Entity('tests', {}, {
  idAttribute: tests => 0
})

const testSchema = new schema.Entity('singleTest', {}, {
  idAttribute: singleTest => 0
})

const categoryTestSchema = new schema.Entity('categoryTest', {}, {
  idAttribute: categoryTest => 0
})

const categoryTestsSchema = new schema.Entity('categoryTests', {}, {
  idAttribute: categoryTests => 0
})

const hpCommentSchema = new schema.Entity('hpComment', {}, {
  idAttribute: hpComment => hpComment.iD
})


const currentMarkerSchema = new schema.Entity('currentMarker', {}, {
  idAttribute: currentMarker => currentMarker.biomarkerResultId
})


const currentMarkerHistorySchema = new schema.Entity('currentHistoryMarker', {}, {
  idAttribute: currentHistoryMarker => 0
})


const currentOrdersSchema = new schema.Entity('currentOrders', {}, {
  idAttribute: currentOrders => 0
})

const completedOrdersSchema = new schema.Entity('completedOrders', {}, {
  idAttribute: completedOrders => 0
})

const subscriptionsSchema = new schema.Entity('subscriptions', {}, {
  idAttribute: subscriptions => 0
})

const orderBiomarkersSchema = new schema.Entity('orderBiomarkers', {}, {
  idAttribute: orderBiomarkers => orderBiomarkers.biomarkerId
})

const categoryTestHistorySchema = new schema.Entity('categoryTestHistory', {}, {
  idAttribute: categoryTestHistory => categoryTestHistory.biomarkerResultId
})

const markerHistorySchema = new schema.Entity('markerHistory', {}, {
  idAttribute: markerHistory => markerHistory.biomarkerResultId
})

const allLatestBiomarkersSchema = new schema.Entity('allLatestBiomarkers', {}, {
  idAttribute: allLatestBiomarkers => 0
})

const blogSchema = new schema.Entity('blog', {}, {
  idAttribute: blog => 0
})

const markerAverageSchema = new schema.Entity('markerAverage', {}, {
  idAttribute: markerAverage => 0
})

const partnerSchema = new schema.Entity('partner', {}, {
  idAttribute: partner => 0
})
 
const parentBespokeTestSchema = new schema.Entity('parentBespokeTest', {}, {
  idAttribute: parentBespokeTest => 0
})

const plusTestsSchema = new schema.Entity('plusTests', {}, {
  idAttribute: plusTests => plusTests.testId
})

const allTestsSchema = new schema.Entity('allTests', {}, {
  idAttribute: allTests => allTests.testId
})

const cartSchema = new schema.Entity('cart', {}, {
  idAttribute: cart => 0
})

const basketTestsSchema = new schema.Entity('basketTestItems', {}, {
  idAttribute: basketTestItems => basketTestItems.testId
})
 
const sharedBiomarker = new schema.Entity('sharedBiomarker', {}, {
  idAttribute: sharedBiomarker => 0
})
 

const partnerClinicsSchema = new schema.Entity('partnerClinics', {}, {
  idAttribute: partnerClinics => partnerClinics.iD
})

const femaleHormoneResultsSchema = new schema.Entity('femaleHormoneResults', {}, {
  idAttribute: femaleHormoneResults => femaleHormoneResults.orderId
})

const ratingsSchema = new schema.Entity('ratings', {}, {
  idAttribute: ratings => ratings.orderId
})

const pdfResults = new schema.Entity('pdfs', {}, {
  idAttribute: pdfs => pdfs.orderId
})
const getStartedQsSchema = new schema.Entity('getStartedQs', {}, {
  idAttribute: getStartedQs => getStartedQs.questionNumber
})

const orderDetailsSchema = new schema.Entity('orderDetails', {}, {
  idAttribute: orderDetails => orderDetails.orderId
})

const getContraceptionTypesSchema = new schema.Entity('getContraceptionTypes', {}, {
  idAttribute: getContraceptionTypes => 0
})

const getContraceptionSelectedSchema = new schema.Entity('getContraceptionSelected', {}, {
  idAttribute: getContraceptionSelected => 0
})

const getSubscriptionAmendSchema = new schema.Entity('subscriptionAmend', {}, {
  idAttribute: test => test.testId
})

const getSubscriptionCardsSchema = new schema.Entity('cardSubscriptions', {}, {
  idAttribute: cardSubscriptions => cardSubscriptions.subscriptionId
})

const questionsList = new schema.Entity('questionsList', {}, {
  idAttribute: questionsList => 0
})

const healthCategories = new schema.Entity('healthCategories', {}, {
  idAttribute: healthCategories => healthCategories.id
})
 
const stripeSubscriptionsSchema = new schema.Entity('stripeSubscriptions', {}, {
  idAttribute: stripeSubscriptions => stripeSubscriptions.id 
})
 
// Schemas for WordPress API responses.
export const Schemas = {
  USER: userSchema,
  USER_ARRAY: [userSchema],
  MEDICAL: medicalSchema,
  MEDICAL_ARRAY: [medicalSchema],
  ORDER: orderSchema,
  ORDER_ARRAY: [orderSchema],
  HORMONE_ORDER: hormoneOrderSchema,
  HORMONE_ORDER_ARRAY: [hormoneOrderSchema],
  LAST_ORDER: lastOrderSchema,
  LAST_ORDER_ARRAY: [lastOrderSchema],
  RESULT: resultSchema,
  RESULT_ARRAY: [resultSchema],
  IMPROVED_RESULTS: improvedResultsSchema,
  IMPROVED_RESULTS_ARRAY: [improvedResultsSchema],
  OUT_OF_RANGE_RESULTS: outOfRangeResultsSchema,
  OUT_OF_RANGE_RESULTS_ARRAY: [outOfRangeResultsSchema],
  COMPLETE_INCOMPLETE_RESULTS: completeIncompleteMarkerSchema, 
  COMPLETE_INCOMPLETE_RESULTS_ARRAY: [completeIncompleteMarkerSchema], 
  TESTS_RESULTS: testsSchema, 
  TESTS_ARRAY: [testsSchema], 
  TEST_RESULTS: testSchema, 
  TEST_ARRAY: [testSchema], 
  CATEGORY_TEST_RESULTS: categoryTestSchema, 
  CATEGORY_TEST_RESULTS_ARRAY: [categoryTestSchema],
  CATEGORY_TESTS_RESULTS: categoryTestsSchema, 
  CATEGORY_TESTS_RESULTS_ARRAY: [categoryTestsSchema],
  HP_COMMENT: hpCommentSchema, 
  HP_COMMENT_ARRAY: [hpCommentSchema], 
  CURRENT_BIOMARKER: currentMarkerSchema, 
  CURRENT_BIOMARKER_ARRAY: [currentMarkerSchema],
  CURRENT_BIOMARKER_HISTORY: currentMarkerHistorySchema, 
  CURRENT_BIOMARKER_HISTORY_ARRAY: [currentMarkerHistorySchema], 
  CURRENT_ORDERS: currentOrdersSchema, 
  CURRENT_ORDERS_ARRAY: [currentOrdersSchema], 
  COMPLETED_ORDERS: completedOrdersSchema, 
  COMPLETED_ORDERS_ARRAY: [completedOrdersSchema], 
  SUBSCRIPTIONS: subscriptionsSchema, 
  SUBSCRIPTIONS_ARRAY: [subscriptionsSchema], 
  ALL_BIOMARKERS_FROM_ORDER: [orderBiomarkersSchema],
  ALL_BIOMARKERS_FROM_ORDER_ARRAY: [orderBiomarkersSchema],
  CATEGORY_TEST_RESULTS_HISTORY: [categoryTestHistorySchema],
  CATEGORY_TEST_RESULTS_HISTORY_ARRAY: [categoryTestHistorySchema],
  BIOMARKER_HISTORY: [markerHistorySchema],
  BIOMARKER_HISTORY_ARRAY: [markerHistorySchema],
  ALL_LATEST_BIOMARKERS: allLatestBiomarkersSchema,
  ALL_LATEST_BIOMARKERS_ARRAY: [allLatestBiomarkersSchema],
  BLOG_POSTS: blogSchema,
  BLOG_POSTS_ARRAY: [blogSchema],
  BIOMARKER_AVERAGE: markerAverageSchema,
  BIOMARKER_AVERAGE_ARRAY:[markerAverageSchema],
  PARTNER: partnerSchema,
  PARTNER_ARRAY: [partnerSchema],
  PARENT_BESPOKE_TEST: parentBespokeTestSchema,
  PARENT_BESPOKE_TEST_ARRAY: [parentBespokeTestSchema],
  PLUS_TESTS: plusTestsSchema,
  PLUS_TESTS_ARRAY: [plusTestsSchema],
  ALL_TESTS: allTestsSchema,
  ALL_TESTS_ARRAY:[allTestsSchema],
  CART:[cartSchema],
  CART_ARRAY: [cartSchema],
  BASKET_TESTS: [basketTestsSchema],
  BASKET_TESTS_ARRAY: [basketTestsSchema],
  SHARED_BIOMARKER: sharedBiomarker,
  SHARED_BIOMARKER_ARRAY: [sharedBiomarker],
  PARTNER_CLINICS: partnerClinicsSchema,
  PARTNER_CLINIC_ARRAY: [partnerClinicsSchema],
  FEMALE_HORMONES_RESULTS: femaleHormoneResultsSchema,
  FEMALE_HORMONES_RESULTS_ARRAY: [femaleHormoneResultsSchema],
  RATINGS_RESULTS: ratingsSchema,
  RATINGS_RESULTS_ARRAY: ratingsSchema,
  GET_STARTED_QS: getStartedQsSchema,
  ORDER_DETAILS: orderDetailsSchema,
  PDF_RESULTS: pdfResults,
  GET_CONTRACEPTION_TYPES: getContraceptionTypesSchema, 
  GET_CONTRACEPTION_SELECTED: getContraceptionSelectedSchema, 
  GET_AMENDED_SUBSCRIPTION: getSubscriptionAmendSchema, 
  GET_SUBSCRIPTION_CARDS: [getSubscriptionCardsSchema], 
  GET_QUESTIONS_LIST: questionsList,
  HEALTH_CATEGORIES: [healthCategories],
  GET_STRIPE_SUBSCRIPTIONS: [stripeSubscriptionsSchema]
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API'

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API]
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let { endpoint } = callAPI
  const { schema, types, method, query, body } = callAPI

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState())
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }
  // if (!schema) {
  //   throw new Error('Specify one of the exported Schemas.')
  // }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  const [ requestType, successType, failureType ] = types
  next(actionWith({ type: requestType }))

  return callApi(endpoint, schema, method, query, body).then(
    response => next(actionWith({
      response,
      type: successType
    })),
    error => next(actionWith({
      type: failureType,
      error: error.message || 'Something bad happened'
    }))
  )
}




