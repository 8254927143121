const dev = {
  STRIPE_KEY: "pk_test_btfEuB0wuGul5TgdgxXp7A8P",
  SALT3: "Jidsngf090934iov4u598bo!oiu8irvhgfiretjvdghjfdrhgvjkdft!",
  SALT5: "@£jkkdshf&$klndsiorvJopeASPWjfjderhfjnvdf!845nK!jnds8844",
  GATESTCODE: "UA-71798345-4",
  GALIFECODE: "UA-71798345-1",
  GAEDGECODE: "UA-71798345-2"
};

const prod = {
  STRIPE_KEY: "pk_test_btfEuB0wuGul5TgdgxXp7A8P",
  SALT3: "Jidsngf090934iov4u598bo!oiu8irvhgfiretjvdghjfdrhgvjkdft!",
  SALT5: "@£jkkdshf&$klndsiorvJopeASPWjfjderhfjnvdf!845nK!jnds8844",
  GATESTCODE: "UA-71798345-4",
  GALIFECODE: "UA-71798345-1",
  GAEDGECODE: "UA-71798345-2"
};


var strippedDownVersion = 1;
if (window.location.hostname == 'app.testinabox.com' || window.location.hostname == 'shop.testinabox.com') {    
  strippedDownVersion = 1;
} 
 
var partnerPlatform = 0; //Needs to be 0 for deployments
if (window.location.hostname == 'forthpartners.co.uk' || window.location.hostname == 'appdev.forthpartners.co.uk' || window.location.hostname == 'beta.forthpartners.co.uk') {    
  partnerPlatform = 1;
}

var websiteId;
if (window.location.hostname == 'localhost' || window.location.hostname == 'app.forthwithlife.co.uk' || window.location.hostname == 'shop.forthwithlife.co.uk' || window.location.hostname == 'appdev.forthwithlife.co.uk') {
  websiteId = 1;
} else if (window.location.hostname == 'app.forthedge.co.uk' || window.location.hostname == 'shop.forthedge.co.uk' || window.location.hostname == 'appdev.forthedge.co.uk') {
  websiteId = 2;
} else if (window.location.hostname == 'app.biomarkertracking.com' || window.location.hostname == 'shop.biomarkertracking.com') {
  websiteId = 3;
} else if ((window.location.hostname == 'biomarkertracking.com' || window.location.hostname == 'partnershopdev.forthwithlife.co.uk') && window.location.hostname != 'app.biomarkertracking.com') {  
  websiteId = 4;
} else if (window.location.hostname == 'forthpartners.co.uk' || window.location.hostname == 'appdev.forthpartners.co.uk' || window.location.hostname == 'beta.forthpartners.co.uk') { 
  websiteId = 5;
}  
websiteId = 1;


var partnerStore = 0;
if ((window.location.hostname == 'biomarkertracking.com' || window.location.hostname == 'partnershopdev.forthwithlife.co.uk') && window.location.hostname != 'app.biomarkertracking.com') {    
  partnerStore = 1;
}


var encryptString = 'kczQnOCzFpvRYSHQOFgb';
 

global.config = {
   strippedDownVersion: strippedDownVersion,
   partnerPlatform: partnerPlatform,
   websiteId: websiteId,
   encryptString: encryptString,
   partnerStore: partnerStore,
   postcoderApiKey: 'PCWSL-BJ4DS-PSZ7V-Z96S2'
};

if (strippedDownVersion == 1) {
  const dev = {
    STRIPE_KEY: "pk_test_btfEuB0wuGul5TgdgxXp7A8P",
    SALT3: "Jidsngf090934iov4u598bo!oiu8irvhgfiretjvdghjfdrhgvjkdft!",
    SALT5: "@£jkkdshf&$klndsiorvJopeASPWjfjderhfjnvdf!845nK!jnds8844",
    GATESTCODE: "UA-71798345-4",
    GALIFECODE: "UA-71798345-1",
    GAEDGECODE: "UA-71798345-2"
  };

  const prod = {
    STRIPE_KEY: "pk_live_HtdzO35fojjONfkBK5VtAzzf",
    SALT3: "Jidsngf090934iov4u598bo!oiu8irvhgfiretjvdghjfdrhgvjkdft!",
    SALT5: "@£jkkdshf&$klndsiorvJopeASPWjfjderhfjnvdf!845nK!jnds8844",
    GATESTCODE: "UA-71798345-4",
    GALIFECODE: "UA-71798345-1",
    GAEDGECODE: "UA-71798345-2"
  };
}

// Default to dev if not set  pk_live_HtdzO35fojjONfkBK5VtAzzf
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
}; 