
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux' 
import RemoveFromBasket from '../../components/modules/RemoveFromBasket'
import { addToCart, removeFromCart, checkVoucherCode, loadAddToBasket } from '../../actions/basket-actions'
import { loadAllTests } from '../../actions/test-actions' 
import find from 'lodash/find'
import _ from 'lodash';
import moment from 'moment';

class BasketSidebar extends Component {

  constructor(props) {
    super(props);  
    this.state = {
      phlebotomyOption: 1,
      subscriptionOption: 3,
      voucherStatus: '',
      voucher: ''
    }; 

    this.updatePhlebotomyOption = this.updatePhlebotomyOption.bind(this)
    this.handleSubscriptionChange = this.handleSubscriptionChange.bind(this)
    this.applyVoucherCode = this.applyVoucherCode.bind(this)
  }

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = { 
    basket: PropTypes.object.isRequired,
    addToCart: PropTypes.func.isRequired,
    checkVoucherCode: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    loadAllTests: PropTypes.object.isRequired,
    loadAddToBasket: PropTypes.func.isRequired
  }

  componentWillMount() {   
    this.props.loadAllTests('me');
  }
  
  handleSubscriptionChange(event) {
    this.setState({subscriptionOption: event.target.value}); 
  }

  updatePhlebotomyOption(phlebotomyOption) {
    this.setState({phlebotomyOption: phlebotomyOption})
  }


  goToCheckout() {
    
    var stateVoucher = localStorage.getItem('voucher');
    var voucher = this.state.voucher;
    var voucherCode = ''; 
    if (!voucher) {
      voucher = JSON.parse(stateVoucher); 
    } else {
      voucher = '';
      voucherCode = '';
    }

    if (voucher) {
      voucherCode = voucher.voucherCode;
    }

    var page = this;
    var date = moment().format('YmdHisu');
    var cookie = date + Math.floor(9999999 + Math.random() * 9999999) + Math.floor(9999999 + Math.random() * 9999999) + Math.floor(9999999 + Math.random() * 9999999);
    var user = this.props.user;
    var subscription = this.state.subscriptionOption;
    var basket = this.props.basket;
    var tests = this.props.allTests;
    var phlebotomy = this.state.phlebotomyOption;
    var items = [];
 

    Object.keys(basket).map(function(key) { 
        find(tests, (test) => {
          if (test.testId === basket[key]) {
            
            items.push(test)
          } else {
            return false;
          }
        })
        
      });

    var grouped = _.mapValues(_.groupBy(items, 'testId'));



    if (!localStorage.getItem('orderCookieId')) {
      localStorage.setItem('orderCookieId', cookie);
    } else {
      cookie = localStorage.getItem('orderCookieId');
    }

    Object.keys(grouped).map(function(key) {
      if (grouped[key][0].subscriptionAvailable == 0) {
        subscription = 0;
      } 
 
      page.props.loadAddToBasket(grouped[key][0].testId, cookie, user.iD, subscription, '', 0, voucherCode, phlebotomy)
      .then(function(response) {
        if (!response.error) { 

          page.props.history.push('/checkout');
          
        }  
      }); 

      
    });
    


  }

  applyVoucherCode(event) {
    var page = this;
    var basket = this.props.basket;
    event.preventDefault();
    this.props.checkVoucherCode(this.refs.voucherCode.value, basket);

    this.props.checkVoucherCode(this.refs.voucherCode.value, basket)
    .then(function(response) {
      if (response.error) { 
        page.setState({voucherStatus: false});

      } else { 
        page.setState({voucherStatus: true, voucher: response.response});
        localStorage.setItem('voucher', JSON.stringify(response.response));
        localStorage.setItem('voucherStatus', true);
      }
    })
  }


  basketItems() {  
    var tests = this.props.allTests;
    var basket = this.props.basket;
    var count = 0;
    var items = [];
    var page = this;  
    if (Object.keys(basket).length > 0) {
      Object.keys(basket).map(function(key) { 
        find(tests, (test) => {
          if (test.testId === basket[key]) {
            
            items.push(test)
          } else {
            return false;
          }
        })
        
      });

      var grouped = _.mapValues(_.groupBy(items, 'testId'));

      return Object.keys(grouped).map(function(key) {
        return (
          <div>
            <div className="product">
              <div className="photo hidden-xs visible-sm visible-md visible-lg">
                <img className="img-fluid" src="/img/kit-life.png" />
              </div>

              <div className="information">
                <h4>{grouped[key][0].testName}
                  <small>(Finger prick)</small>
                  <span className="price">
                    £{grouped[key][0].testPrice} per check
                  </span>
                </h4>
                <h5>Quantity: <strong>{grouped[key].length}</strong></h5>
                <p className="hidden-xs visible-sm visible-md visible-lg">This product includes analysis of <strong>{grouped[key][0].biomarkers.length}</strong> biomarkers by an accredited lab and access to your personalised health dashboard.
                  <br/><br/>
                  <a href="/bespoke-test/&amp;pt=45&amp;rtn=6/">Customise this test</a>                
                </p>
        
                <div className="clearfix"></div>
                {page.subscriptionOptions(grouped[key][0].testId, grouped[key][0].subscriptionAvailable)}
              </div>
              
              <RemoveFromBasket testId={grouped[key][0].testId}/>
            </div>
          </div>

        )
      }); 

 
    } else { 
      return (
        <div>
          Your basket is currently empty
        </div>
      )
    }

  }

  subscriptionOptions(testId, subscriptionAvailable) {
    var allowedSubscriptionMonths = [];
    var subscriptionOption = this.state.subscriptionOption;

    if (subscriptionAvailable == 1) {
      allowedSubscriptionMonths = [3,4,6,12];
    } else if (subscriptionAvailable == 2) {
      allowedSubscriptionMonths = [3,4,6,12];
    } else if (subscriptionAvailable == 3) {
      allowedSubscriptionMonths = [1,3,4,6];
    }
 
    var options = [];

    allowedSubscriptionMonths.forEach(function(option) {
      var opts = {};
      if (subscriptionOption == option) {
        opts['selected'] = 'selected';
         
      }
      options.push(<option {...opts} value={option}>Every {option} Month(s)</option>)
    });
    
    if (subscriptionAvailable != 0) {
      return (
        <div>
          <hr/>
          <p>
            Select how often you would like to receive this test. 
            You can cancel or change the frequency of your test at any time using your dashboard.
            
            <select onChange={this.handleSubscriptionChange} name="billingFrequency" id="billingFrequency" className="billingFrequencyOptions custom-select">
              {options}
            </select>

          </p>
        </div>
      )
    }
  }


  phlebotomyOptions() {
    var phlebotomyOption = this.state.phlebotomyOption;
    var basket = this.props.basket;
    if (Object.keys(basket).length > 0) {
      return (
        <div className="product">
          <div className="clinicArea">
            <div className="stage1">
              <p className="hideonsearch">
                This product includes a finger prick kit. You have the option to switch to a home phlebotomy for Baseline. This means a healthcare professional will visit you at home to draw the blood sample from a vein.
              </p>

              <a onClick={() => this.updatePhlebotomyOption(1)} className="phlebotomySelectionBtn">
                <div className={"tickCircle " + (phlebotomyOption == 1 ? 'checked' : '')}></div> Finger prick kit - <span>FREE</span>
              </a>

              <a onClick={() => this.updatePhlebotomyOption(2)} className="phlebotomySelectionBtn">
                <div className={"tickCircle " + (phlebotomyOption == 2 ? 'checked' : '')}></div> Home phlebotomy appointment- <span>£45</span>
              </a>

              <a onClick={() => this.updatePhlebotomyOption(3)} className="phlebotomySelectionBtn">
                <div className={"tickCircle " + (phlebotomyOption == 3 ? 'checked' : '')}></div> Organise a phlebotomist myself - <span>FREE</span>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }

  basketTotal() {
    var basket = this.props.basket;
    var tests = this.props.allTests;
    var phlebotomyOption = this.state.phlebotomyOption;
    var stateVoucher = localStorage.getItem('voucher');
    var voucher = this.state.voucher;
    if (!voucher) {
      voucher = JSON.parse(stateVoucher); 
    } 

    var testPrice = 0.00;
    var orderDiscount = 0;
    var vouchersToDiscount = '';
    var singleTestPrice = 0;
    if (voucher) {
      vouchersToDiscount = voucher.vouchersToDiscount;
    }

    if (Object.keys(basket).length > 0) {
      Object.keys(basket).map(function(key) { 
        find(tests, (test) => {
          if (test.testId === basket[key]) {
           
            if (voucher && vouchersToDiscount.length > 0)  {
              vouchersToDiscount.forEach(function (item) {

                if (parseInt(test.testId) === parseInt(item)) {
 
                  singleTestPrice = parseInt(test.testPrice);
            
                  if (voucher.discountType == 1) {
                    if (singleTestPrice  <= voucher.discountAmount) {
                      orderDiscount = singleTestPrice;
                    } else {
                      orderDiscount = voucher.discountAmount;
                    }
                  }

                  if (voucher.discountType == 2) {
                    orderDiscount = singleTestPrice  * (voucher.discountAmount/100);
                  }

                  singleTestPrice = singleTestPrice - orderDiscount; 
  
                  testPrice = testPrice + singleTestPrice;
                } else {
                  testPrice = testPrice + parseInt(test.testPrice);
                }
              });
            } else {
              testPrice = testPrice + parseInt(test.testPrice);
            }
            
             
          }
        })


        
        
      });

      var phlebotomyPrice = 45;

      if (phlebotomyOption == 2) {

        if (voucher && voucher.includePhlebotomy) {
            
          if (voucher.discountType == 2) {
            phlebotomyPrice = 45 * (voucher.discountAmount/100);
            orderDiscount = orderDiscount + phlebotomyPrice;
            testPrice = testPrice + phlebotomyPrice;
          } else {
            testPrice = testPrice + 45;
          }
 
             
       
           
        } else {
          
          testPrice = testPrice + 45;
  
        }
         

      }
 

      if (voucher) {

        

        if (vouchersToDiscount.length == 0)  {



          if (voucher.includePhlebotomy != true && phlebotomyOption == 2) {
            testPrice = testPrice - 45;
          }
   
          if (voucher.discountType == 1) {
            if (testPrice  <= voucher.discountAmount) {
              orderDiscount = testPrice;
            } else {
              orderDiscount = voucher.discountAmount;
            }

            testPrice = testPrice - orderDiscount;
          }

          if (voucher.discountType == 2) {
            orderDiscount = testPrice  * (voucher.discountAmount/100);
            testPrice = testPrice - orderDiscount;
          }

          if (voucher.includePhlebotomy != true && phlebotomyOption == 2) {
            testPrice = testPrice + 45;
          }

        }
        
        

      } 
      

 

      return (
        <div className="totalContainer">
          <div className="totalAmount">
            Total: £{testPrice}   
          </div>
          <div onClick={() => this.goToCheckout()}  className="checkoutButton">
            Go to checkout    
          </div>
        </div>
      )
    }
    
  }


  shippingInfo() {
    var basket = this.props.basket;
    if (Object.keys(basket).length > 0) {
      return (
        <div className="product">
          <div className="photo hidden-xs visible-sm visible-md visible-lg">
            <img className="img-fluid" src="/img/basketDelivery.jpg" />
          </div>

          <div className="information">
            <h4>Postage and packaging
                <span className="price">Free</span>
             </h4>
             <p>Your kit will be posted 1<sup>st</sup> class &amp; will fit through your letter box. It will contain a free-post envelope to return your sample direct to our lab.</p>
          </div>
        </div>
      )
    }
  }


  voucherCode() {
    var basket = this.props.basket;
    var page = this;
    if (Object.keys(basket).length > 0) {
      return (
        <div className="col-12 pb-3">
          <h4 align="center">Have a voucher or affiliate code?</h4>
          <form align="center" onSubmit={page.applyVoucherCode}>
            {(() => {
              if (page.state.voucherStatus === true || localStorage.getItem('voucherStatus')) {

                return (
                  <div>Voucher code applied</div>
                )


              } else if (page.state.voucherStatus === false) {
                return (
                  <div>Invalid voucher</div>
                )
              } else if (page.state.voucherStatus === '') {
                return (
                  <div>
                    <input type="text" ref="voucherCode" id="voucherCode" name="voucherCode" className="form-control inline mobileStackBottomMargin" placeholder="Enter your code" />  
                    <button type="submit" id="voucherCodeBtn" >Apply</button>
                  </div>
                )
              }
            })()}
          </form>
        </div>
      )
    }
    
  }

 

  render() {
    return (
      <div className="border-left" id="basket-wrapper">
        <div className="list-group list-group-flush">
          <div className="container text-center">
            <h2>Basket</h2>
            {this.basketItems()}
            {this.phlebotomyOptions()}
            {this.shippingInfo()}
            {this.voucherCode()}
            {this.basketTotal()}
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => {

  const {
    entities: { allTests },
    basket,
    authentication, 
    user
  } = state
 

  return {
    authentication, 
    user,
    basket,
    allTests
  }
}

export default withRouter(connect(mapStateToProps, { 
  addToCart,
  loadAllTests,
  removeFromCart,
  checkVoucherCode,
  loadAddToBasket
})(BasketSidebar))