import { CALL_API, Schemas } from '../middleware/api'

export const TESTS_REQUEST = 'TESTS_REQUEST'
export const TESTS_SUCCESS = 'TESTS_SUCCESS'
export const TESTS_FAILURE = 'TESTS_FAILURE'

const fetchAllTests = () => ({
  [CALL_API]: {
    types: [ TESTS_REQUEST, TESTS_SUCCESS, TESTS_FAILURE ],
    endpoint: `tests/all-tests`,
    schema: Schemas.ALL_TESTS_ARRAY
  }
})

export const loadAllTests = () => (dispatch, getState) => {
  const tests = getState().entities.allTests;
  if (Object.keys(tests).length > 0) {
    return null
  }

  return dispatch(fetchAllTests())
}

const fetchCompletedTests = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ TESTS_REQUEST, TESTS_SUCCESS, TESTS_FAILURE ],
    endpoint: `secure/results/all-results/${userId}`,
    schema: Schemas.TESTS_RESULTS
  }
})

export const loadCompletedTests = (userId) => (dispatch, getState) => {
  const tests = getState().entities.tests;
  if (Object.keys(tests).length > 0) {
    return null
  }

  return dispatch(fetchCompletedTests(userId))
}


export const TEST_REQUEST = 'TEST_REQUEST'
export const TEST_SUCCESS = 'TEST_SUCCESS'
export const TEST_FAILURE = 'TEST_FAILURE'


const fetchTestResults = (orderId) => ({
  orderId,
  [CALL_API]: {
    types: [ TEST_REQUEST, TEST_SUCCESS, TEST_FAILURE ],
    endpoint: `/secure/results/get-test-results/${orderId}`,
    schema: Schemas.TEST_RESULTS
  }
})

export const loadTestResults = (orderId) => (dispatch, getState) => {
  const singleTest = getState().entities.singleTest;
 
  if (Object.keys(singleTest).length > 0) {
    Object.keys(singleTest).map(function(key) {

      delete singleTest[key];

    })  
  }

  return dispatch(fetchTestResults(orderId))
}


export const CATEGORY_TEST_REQUEST = 'CATEGORY_TEST_REQUEST'
export const CATEGORY_TEST_SUCCESS = 'CATEGORY_TEST_SUCCESS'
export const CATEGORY_TEST_FAILURE = 'CATEGORY_TEST_FAILURE'


const fetchCategoryTestResults = (orderId, categoryType, categoryId) => ({
  orderId,
  categoryType,
  categoryId,
  [CALL_API]: {
    types: [ CATEGORY_TEST_REQUEST, CATEGORY_TEST_SUCCESS, CATEGORY_TEST_FAILURE ],
    endpoint: `/secure/results/get-sub-test-results/${orderId}/${categoryType}/${categoryId}`,
    schema: Schemas.CATEGORY_TEST_RESULTS
  }
})

export const loadCategoryTestResults = (orderId, categoryType, categoryId) => (dispatch, getState, setState) => {
  var categoryTest = getState().entities.categoryTest;
  if (Object.keys(categoryTest).length > 0) {
    Object.keys(categoryTest).map(function(key) {

      delete categoryTest[key];

    })  
  }

  return dispatch(fetchCategoryTestResults(orderId, categoryType, categoryId))
}
 

export const CATEGORY_TEST_HISTORY_REQUEST = 'CATEGORY_TEST_HISTORY_REQUEST'
export const CATEGORY_TEST_HISTORY_SUCCESS = 'CATEGORY_TEST_HISTORY_SUCCESS'
export const CATEGORY_TEST_HISTORY_FAILURE = 'CATEGORY_TEST_HISTORY_FAILURE'


const fetchCategoryTestResultsHistory = (testId, categoryType, categoryId) => ({
  testId,
  categoryType,
  categoryId,
  [CALL_API]: {
    types: [ CATEGORY_TEST_HISTORY_REQUEST, CATEGORY_TEST_HISTORY_SUCCESS, CATEGORY_TEST_HISTORY_FAILURE ],
    endpoint: `/secure/results/get-sub-test-results-history/${testId}/${categoryType}/${categoryId}`,
    schema: Schemas.CATEGORY_TEST_RESULTS_HISTORY
  }
})

export const loadCategoryTestResultsHistory = (testId, categoryType, categoryId) => (dispatch, getState, setState) => {
  var categoryTestHistory = getState().entities.categoryTestHistory;
  if (Object.keys(categoryTestHistory).length > 0) {
    Object.keys(categoryTestHistory).map(function(key) {

      delete categoryTestHistory[key];

    })  
  }

  return dispatch(fetchCategoryTestResultsHistory(testId, categoryType, categoryId))
}

export const CATEGORY_TESTS_REQUEST = 'CATEGORY_TESTS_REQUEST'
export const CATEGORY_TESTS_SUCCESS = 'CATEGORY_TESTS_SUCCESS'
export const CATEGORY_TESTS_FAILURE = 'CATEGORY_TESTS_FAILURE'


const fetchCategoryTests = (orderId, categoryType, categoryId) => ({
  orderId,
  categoryType,
  categoryId,
  [CALL_API]: {
    types: [ CATEGORY_TESTS_REQUEST, CATEGORY_TESTS_SUCCESS, CATEGORY_TESTS_FAILURE ],
    endpoint: `/secure/results/all-category-results/${orderId}/${categoryType}/${categoryId}`,
    schema: Schemas.CATEGORY_TESTS_RESULTS
  }
})

export const loadCategoryTests = (orderId, categoryType, categoryId) => (dispatch, getState) => {
  const categoryTests = getState().entities.categoryTests;
 
  if (Object.keys(categoryTests).length > 0) {
    Object.keys(categoryTests).map(function(key) {

      delete categoryTests[key];

    })  
  }
  return dispatch(fetchCategoryTests(orderId, categoryType, categoryId))
}


export const BASKET_TEST_REQUEST = 'BASKET_TEST_REQUEST'
export const BASKET_TEST_SUCCESS = 'BASKET_TEST_SUCCESS'
export const BASKET_TEST_FAILURE = 'BASKET_TEST_FAILURE'


const fetchBasketTests = (basketTests, basket) => ({
  basketTests,
  basket,
  [CALL_API]: {
    types: [ BASKET_TEST_REQUEST, BASKET_TEST_SUCCESS, BASKET_TEST_FAILURE ],
    endpoint: `/tests/basket-tests/${basketTests}/${basket}`,
    schema: Schemas.BASKET_TESTS_ARRAY
  }
})

export const loadBasketTests = (basketTests, basket) => (dispatch, getState, setState) => {
  var basketTestItems = getState().entities.basketTestItems;
  if (Object.keys(basketTestItems).length > 0) {
    Object.keys(basketTestItems).map(function(key) {

      delete basketTestItems[key];

    })  
  }

  return dispatch(fetchBasketTests(basketTests, basket))
}

export const GET_SINGLE_TEST_REQUEST = 'GET_SINGLE_TEST_REQUEST'
export const GET_SINGLE_TEST_SUCCESS = 'GET_SINGLE_TEST_SUCCESS'
export const GET_SINGLE_TEST_FAILURE = 'GET_SINGLE_TEST_FAILURE'


const fetchTest = (testId) => ({
  testId,
  [CALL_API]: {
    types: [ GET_SINGLE_TEST_REQUEST, GET_SINGLE_TEST_SUCCESS, GET_SINGLE_TEST_FAILURE ],
    endpoint: `/tests/get-test/${testId}`,
    schema: Schemas.GET_AMENDED_SUBSCRIPTION
  }
})

export const loadTest = (testId) => (dispatch, getState, setState) => {
  var subscriptionAmend = getState().entities.subscriptionAmend;
  if (Object.keys(subscriptionAmend).length > 0) {
    Object.keys(subscriptionAmend).map(function(key) {

      delete subscriptionAmend[key];

    })  
  }
  return dispatch(fetchTest(testId))
}


