import merge from 'lodash/merge'
import { combineReducers } from 'redux'

import * as ActionTypes from '../actions'
import * as AuthenticationActionTypes from '../actions/authentication-actions'
import * as UsersActionTypes from '../actions/users-actions'
import * as BasketActionTypes from '../actions/basket-actions'

// Updates an entity cache in response to any action with response.entities.


const entities = (state = {stripeSubscriptions: {}, healthCategories: {}, questionsList: {}, subscriptionAmend: {}, cardSubscriptions: {}, orderDetails: {}, getContraceptionSelected: {}, getContraceptionTypes: {}, getStartedQs: {}, pdfs: {}, ratings: {}, hormoneOrder: {}, femaleHormoneResults: {}, partnerClinics: {}, basketTestItems: {}, plusTests: {}, parentBespokeTest: {}, cart: {}, allTests: {}, partner: {}, markerAverage: {}, blog: {}, allLatestBiomarkers:{}, markerHistory: {}, categoryTestHistory: {}, orderBiomarkers: {}, subscriptions: {}, completedOrders: {}, currentOrders: {}, categoryTests: {}, categoryTest: {}, currentHistoryMarker: {}, currentMarker: {}, singleTest: {}, hpComment: {}, tests: {}, completeIncompleteMarker: {}, outOfRangeResults: {}, improvedResults: {}, lastOrder: {}, result: {}, order: {}, medical: {}, users: {} }, action) => {
  if (action.type == 'LOGOUT') { 
    return state = {stripeSubscriptions: {}, questionsList: {}, subscriptionAmend: {}, cardSubscriptions: {}, orderDetails: {}, getContraceptionSelected: {}, getContraceptionTypes: {}, getStartedQs: {}, pdfs: {}, ratings: {}, hormoneOrder: {}, femaleHormoneResults: {}, partnerClinics: {}, basketTestItems: {}, plusTests: {}, parentBespokeTest: {}, cart: {}, allTests: {}, partner: {}, markerAverage: {}, blog: {}, allLatestBiomarkers:{}, markerHistory: {}, categoryTestHistory: {}, orderBiomarkers: {}, subscriptions: {}, completedOrders: {}, currentOrders: {}, categoryTests: {}, categoryTest: {}, currentHistoryMarker: {}, currentMarker: {}, singleTest: {}, hpComment: {}, tests: {}, completeIncompleteMarker: {}, outOfRangeResults: {}, improvedResults: {}, lastOrder: {}, result: {}, order: {}, medical: {}, users: {} };
  } else if (action.response && action.response.entities && action.type != 'LOGOUT') { 
    return merge({}, state, action.response.entities)
  }

  return state
}

const token = localStorage.getItem('token');
const authenticationInitialState = token ? {isAuthenticated: true, isAuthenticating: false, token: token} : {isAuthenticated: false, isAuthenticating: false, token: ''};

// Updates the authenticated state in response to any token or validate request
const authentication = (state = authenticationInitialState, action) => {
  const { type } = action
  // Get current token from localStorage
  const currentToken = localStorage && localStorage.getItem('token') ? localStorage.getItem('token') : false
  // If Offline and we have a token set alreadyAuthenticated to true
  const alreadyAuthenticated = currentToken ? true : false
  switch (type) {
    case AuthenticationActionTypes.TOKEN_REQUEST:
    case AuthenticationActionTypes.VALIDATE_REQUEST:
      return merge({}, state, {
        isAuthenticated: alreadyAuthenticated,
        isAuthenticating: true
      })
    case AuthenticationActionTypes.TOKEN_SUCCESS:
      localStorage.setItem('token', action.response.token);
      return merge({}, state, {
        isAuthenticated: true,
        isAuthenticating: false
      })
    case AuthenticationActionTypes.VALIDATE_SUCCESS:
      return merge({}, state, {
        isAuthenticated: true,
        isAuthenticating: false
      })
    case AuthenticationActionTypes.TOKEN_FAILURE:
    case AuthenticationActionTypes.VALIDATE_FAILURE:
      if (!alreadyAuthenticated || navigator.onLine) {
        localStorage.removeItem('token')
        return merge({}, state, {
          isAuthenticated: false,
          isAuthenticating: false,
          token: ''
        })
      }
      return merge({}, state, {
        isAuthenticated: alreadyAuthenticated || !navigator.onLine,
        isAuthenticating: false
      })
    case AuthenticationActionTypes.LOGOUT:
      var appWindow = window;

      let tmp = appWindow.postMessage(JSON.stringify({type: 'logout', response: ''}), '*');
 
      localStorage.removeItem('token') 
      return merge({}, state, {
        isAuthenticated: false,
        isAuthenticating: false,
        token: ''
      })
    default:
  }
  return state
}

// Updates global settings in response to any options/settings/root requests
const settings = (state = {}, action) => {
  const { type } = action

  switch (type) {
    case ActionTypes.OPTIONS_SUCCESS:
    case ActionTypes.SETTINGS_SUCCESS:
    case ActionTypes.ROOT_SUCCESS:
      return merge({}, state, action.response)
    default:
  }

  return state
}

// Updates current user information in response to token and user requests
const user = (state = {}, action) => {
  const { type } = action
  switch (type) {
    case AuthenticationActionTypes.TOKEN_SUCCESS:
      return merge({}, state, action.response)
    case AuthenticationActionTypes.LOGOUT:
      return {}
    case UsersActionTypes.USER_SUCCESS:
      if (action.userId === 'me') {
        return merge({}, state, action.response)
      }
      break
    case UsersActionTypes.UPDATE_USER_SUCCESS:
      return merge({}, state, action.response);
    default:
  }
  return state
}

 
var basketItems = localStorage.getItem('basket');


if (basketItems == '' || !basketItems) {
  basketItems = {}; 
} else { 
  basketItems = JSON.parse(localStorage.getItem("basket"));
} 

const basket = (state = basketItems, action) => {
  const { type } = action

  switch (type) {
    case BasketActionTypes.ADD_TO_BASKET:
      return merge({}, state, action.id)
    case BasketActionTypes.REMOVE_FROM_BASKET:
      delete state[action.id];
      return state;
    case BasketActionTypes.REMOVE_ALL_FROM_BASKET:
      state = {};
      return state;
    default:
  }
  return state
}

// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
  const { type, error } = action

  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null
  } else if (error) {
    return error
  }

  return state
}

const rootReducer = combineReducers({
  entities,
  authentication,
  user,
  basket,
  errorMessage
})

export default rootReducer
