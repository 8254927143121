import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types' 

export default class BackButton extends Component {

  static contextTypes = {
    router: PropTypes.object
  }



  render() {
    return (
      <button
        className="d-block d-md-none button back-button icon-left"
        onClick={this.context.router.history.goBack}>
          <i className="fas fa-chevron-left"></i> Back
      </button>
    )
  }
}