import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import $ from 'jquery'; 
import { Collapse } from 'bootstrap';
import { NavLink } from 'react-router-dom'

import { logout } from '../actions/authentication-actions'
import { version } from '../../package.json';

//import { toggleAlerts } from '../utils';
 

class Sidebar extends Component {

  constructor(props) {
    super(props); 
    this.handleLogout = this.handleLogout.bind(this);
  }
 
  static propTypes = {
    authentication: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired, 
    logout: PropTypes.func.isRequired
  }

  componentDidMount() { 
    var page = this;
    $(function() {
      $("#sidebar-wrapper a").click(function(e) { 
        $("#wrapper").addClass("toggled");
      });
    //   // $(".dropdown-menu").on("click", "[data-keepopenonclick]", function(e) {
    //   //   e.stopPropagation();
    //   // });
    //   // $(".dropdown-menu > li a").on("click", function(e) {
    //   //   var link = $(this).attr('href');
    //   //   page.props.history.push(link)
    //   // });
    //   // $(".dropdown-menu .logout").on("click", function(e) {
    //   //   e.stopPropagation(); 
    //   //   page.handleLogout();
    //   // });
    });
  }

  handleLogout() {  
 
    var appWindow = window;
 
    appWindow.postMessage(JSON.stringify({type: 'logout', response: ''}), '*');
    this.props.logout();
  }

  render() {
    
    const user = this.props.user;
    const authentication = this.props.authentication;
    const userId = user.iD; 
    const order = this.props.order;
    const lastOrder = this.props.lastOrder;

    var orderId = Object.keys(order)[0];
    var lastOrderId = Object.keys(lastOrder)[0];
    var partnerId = 0;
    var shopLink;

    var isIosWebview =/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = window.isRNWebView;
    
    if ((window.location.hostname == 'app.forthwithlife.co.uk' || window.location.hostname == 'shop.forthwithlife.co.uk') && (isAndroid || isIosWebview)) {

      shopLink = <a className='nav-link show' href={'https://www.forthwithlife.co.uk/our-tests/?app'} target='_blank' >Shop</a>;

    } else if (((window.location.hostname == 'app.forthedge.co.uk') || (window.location.hostname == 'localhost')) && (isAndroid || isIosWebview)) {

      shopLink = <a className='nav-link show' href={'https://www.forthedge.co.uk/our-tests/?app'} target='_blank' >Shop</a>;

    } else{

       shopLink = '';

    }

    //If assigned to partner, overide and remove link to shops

    if (Object.keys(order).length > 0) {

      partnerId = order[orderId].partnerId; 
      if (partnerId != 0 && Object.keys(order[orderId].partner).length > 0 && order[orderId].partner.appLogo != '') {
        shopLink = '';
      }
    
    } else if (Object.keys(lastOrder).length > 0 && lastOrder[lastOrderId].partnerId != 0) {

      partnerId = lastOrder[lastOrderId].partnerId; 
      if (partnerId != 0 && lastOrder[lastOrderId].partner.appLogo != '') {
        shopLink = '';
      }

    } 

    return (
      <div className="border-right" id="sidebar-wrapper">
        <div className="list-group list-group-flush">
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/" activeClassName="active">Dashboard</NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/results" activeClassName="active">My Results</NavLink>
          </li>

            {shopLink}

          {userId != 94 && userId != 405 ? (
            <div>
            <hr/>

            <li className="nav-item">
              <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link" to="/my-account">Manage profile</NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link" to="/my-orders">Orders</NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link" to="/my-subscriptions">Subscriptions</NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link" to="/my-settings">Settings</NavLink>
            </li>
            {user.checkoutCredit != 0 && (
              <li className="nav-item">
                <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link" to="/account-credit">Account Credit</NavLink>
              </li>
            )}
            {window.location.hostname == 'app.biomarkertracking.com' || window.location.hostname == 'localhost' ? (
              <li className="nav-item">
                <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link" to="/third-party-access">Third Party Access</NavLink>
              </li>
            ):(
              <div></div>
            )}

            {isAndroid || isIosWebview ? (
              <li className="nav-item">
                <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link" to="/exclusive-offers">Exclusive Offers</NavLink>
              </li>
            ):(
              <div></div>
            )}

            
            <hr/>


            </div>
          ) : (
            <div></div>
          )} 
          <span data-keepopenonclick onClick={this.handleLogout} className="nav-link logout">Log out</span>
          <div className="versionNumber">Version: {version}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const {
    entities: { order, lastOrder },
    authentication, 
    user
  } = state
 

  return {
    authentication, 
    user,
    order,
    lastOrder
  }
}

export default withRouter(connect(mapStateToProps, { 
  logout
})(Sidebar))
